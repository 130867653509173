<template>
  <div
    class="sf-card sf-card--article"
    :image="placeholderImage"
    :title="title"
    :titleLevel="3"
    :description="description"
    link=""
    buttonText=""
    imageWidth="0"
    imageHeight="0"
  >
    <SfLink class="sf-card__link" :link="link">
      <BynderAsset :data="bynderAsset.data" :size="bynderAsset.size" :ratio="bynderAsset.ratio" />
    </SfLink>
    <div class="sf-card__details">
      <h4 v-if="category" class="sf-heading sf-card__category" v-html="category" />
      <h3 v-if="title" class="sf-heading sf-card__title" v-html="title" />
      <HTMLContent v-if="description" class="sf-card__description" tag="p" :content="truncateWords(description, 32)" />
      <footer class="sf-card__readmore">
        <SfLink class="sf-card__readmore" :link="link">
          <span>{{ $t('Read more') }}</span
          ><ChevronRightIcon class="desktop-only" />
        </SfLink>
      </footer>
      <br-manage-content-button v-if="content" :content="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import HTMLContent from '~/storefront/components/HTMLContent.vue'
import { truncateWords } from '@/utils/stringHelpers'
import ChevronRightIcon from '~/storefront/assets/icons/chevron-right.svg'

interface Props {
  asset: string | boolean
  title: string | boolean
  category: string | boolean
  description: string | boolean
  image: string | boolean
  link: string | boolean
  content?: Object
}

const props = defineProps<Props>()

const bynderAsset = computed(() => {
  return {
    data: props.asset,
    size: '(max-width: 1023px) 100vw, 400px',
    ratio: 1,
  }
})
const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
</script>

<style lang="scss">
html.theme--storefront {
  .sf-card--article {
    position: relative;
    display: flex;
    flex-direction: column;
    width: unset;
    overflow: hidden;
    border-radius: var(--spacer-2xs);

    @include for-desktop {
      border-radius: 0;
    }

    .sf-heading {
      --heading-text-align: left;
    }

    .sf-card__image {
      display: none;
    }

    .sf-card__details {
      flex-grow: 1;
      text-align: left;
      padding: var(--spacer-xs) var(--spacer-xs) var(--spacer-sm);
      background-color: var(--gray-background-color);
      display: flex;
      flex-direction: column;

      @include for-desktop {
        padding: var(--spacer-sm);
      }
    }

    .sf-card__category {
      font-family: var(--font-family--primary);
      font-size: var(--font-size-10);
      line-height: var(--spacer-18);
      margin-bottom: var(--spacer-2xs);
      padding-bottom: 0;

      @include for-desktop {
        font-family: var(--font-family--primary);
        font-size: var(--font-size-13);
        line-height: var(--spacer-18);
        margin-bottom: var(--spacer-xs);
      }
    }

    .sf-card__title {
      font-size: var(--font-size-sm);
      line-height: var(--global-line-height);
      margin-bottom: var(--spacer-2xs);
      padding-bottom: 0;

      @include for-desktop {
        font-size: var(--font-size-18);
        line-height: var(--lg-line-height);
        margin-bottom: var(--spacer-xs);
      }
    }

    .sf-card__description {
      font-size: var(--font-size-12);
      line-height: 1.5;
      margin-bottom: var(--spacer-2xs);

      @include for-desktop {
        font-size: var(--font-size-sm);
        line-height: 1.4286;
        margin-bottom: var(--spacer-xs);
      }
    }

    .sf-card__action {
      position: static;
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;
      font-size: 0;
      left: 0;
      transform: none;
    }

    .sf-card__readmore {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;

      .sf-link {
        display: flex;
        align-items: center;
        padding: 2px 8px;
        --link-text-decoration: underline;
        --link-font-family: var(--font-family--primary);
        --link-font-weight: var(--font-weight--normal);
        --link-line-height: 20px;
        --link-color: var(--black-color);

        @include for-desktop {
          --link-font-size: 16px;
          --link-line-height: 1.4286;
          padding: 5px 0;

          &:hover {
            color: var(--cta-pink-color);
          }
        }
      }

      svg {
        margin-left: var(--spacer-12);
        margin-right: var(--spacer-12);
      }
    }

    // Latest article type
    &-latest {
      @include for-desktop {
        .bynder-asset {
          &:before {
            --padding-top: 83.771%; // 511px
          }
        }
      }

      .sf-card__title {
        @include for-desktop {
          font-size: var(--font-size-21);
          line-height: var(--lg-line-height);
        }
      }

      .sf-card__details {
        @include for-desktop {
          padding: var(--spacer-sm) var(--spacer-base);
        }
      }
    }
  }
}
</style>

<template>
  <div class="sf-accordion-item">
    <slot
      name="header"
      v-bind="{
        header,
        isOpen,
        accordionClick,
        showChevron: $parent.showChevron,
      }"
    >
      <SfButton
        class="sf-button--pure sf-accordion-item__header"
        :class="{ 'is-open': isOpen }"
        :aria-pressed="isOpen.toString()"
        :aria-expanded="isOpen.toString()"
        :data-testid="'accordion-item-' + header"
        @click="accordionClick"
      >
        {{ header }}
        <slot name="additional-info" />
        <SfChevron class="sf-accordion-item__chevron" :class="{ 'sf-chevron--right': !isOpen }" tabindex="0" />
      </SfButton>
    </slot>
    <div class="accordion-item__expanded" :transition="$parent.transition">
      <div :class="{ 'display-none': !isOpen }">
        <div class="sf-accordion-item__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggle'])

const isOpen = ref(props.open)

const accordionClick = () => (isOpen.value = !isOpen.value)
</script>
<style lang="scss">
@use './styles/organisms/SfAccordion.scss';
</style>

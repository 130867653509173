<template>
  <div v-if="component" :class="['photoslurp-component', isPreview && 'has-edit-button']">
    <br-manage-content-button :content="content" />
    <h2 v-if="title" v-html="title" />
    <p v-if="subtitle" v-html="subtitle" />
    <ps-widget :data-config="photoslurpId"></ps-widget>
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'
const { PHOTOSLURP_ALBUM_ID } = useConstants()

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const params = computed(() => props.component?.model?.meta?.paramsInfo)

const isPreview = computed(() => props.page?.isPreview())

const model = computed(() => props.component.getModels()?.document)

const content = computed(() => props.page.getContent(model.value))

const title = computed(() => getParam('title', false))

const subtitle = computed(() => getParam('subtitle', false))

const photoslurpId = computed(() => getParam('id', false))

const isMasonry = computed(() => params.value.type === 'Masonry')

const albumId = computed(() => getParam('albumId') || PHOTOSLURP_ALBUM_ID)

const getParam = (key: string | number, fallback = false) => {
  const value = props.component.model.meta.paramsInfo[key]

  if (value) {
    return value
  }

  return fallback
}

const addScriptToHeader = () => {
  const script = document.createElement('script')
  script.src = 'https://static.photoslurp.com/widget/v3/loader.js'
  script.type = 'application/javascript'
  script.defer = true
  script.async = true
  document.head.appendChild(script)
}

const { locale } = useI18n()

onMounted(() => {
  window.photoSlurpWidgetSettings = window.photoSlurpWidgetSettings || {}
  window.photoSlurpWidgetSettings[photoslurpId.value] = {
    lang: locale.value,
    widgetId: photoslurpId.value,
    albumId: albumId.value,
    widgetType: isMasonry.value ? 'gallery' : 'carousel',
    pageLimit: 30,
    autoscrollLimit: 5,
    socialIcons: false,
    visibleProducts: 4,
    thumbOverlay: true,
    varyingThumbSizes: true,
    showSubmit: false,
    noteAddPicsText: '',
  }

  addScriptToHeader()
})
</script>

<style lang="scss">
html.theme--storefront {
  .photoslurp-component {
    margin-bottom: var(--mj-component-margin-bottom);

    > h2,
    > p {
      text-align: center;
      padding-left: var(--spacer-sm);
      padding-right: var(--spacer-sm);
    }

    > p {
      margin-bottom: var(--spacer-sm);
    }

    .ps-container {
      width: auto;
    }

    .ps-carousel.ps-theme-modern .ps-slider-container {
      overflow: hidden;
    }

    .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal {
      border-radius: 0;
    }

    .ps-gallery.ps-theme-modern .ps-load-more-button {
      color: var(--black-color);
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal {
      padding: 0;
      position: relative;
      overflow: hidden;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal:hover .ps-next {
      right: 0;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal:hover .ps-prev {
      left: 0;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal .ps-image + .ps-image {
      margin-left: 5px;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal .ps-img-container {
      height: 330px;
    }
    .ps-widget-ready
      .ps-carousel.ps-theme-modern
      .ps-carousel-slider.ps-slider-horizontal
      .ps-slider-container
      .ps-image
      img {
      object-fit: cover;
      width: 100% !important;
    }
    .ps-widget-ready
      .ps-carousel.ps-theme-modern
      .ps-carousel-slider.ps-slider-horizontal
      .ps-slider-container
      .ps-image {
      width: 25% !important;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal .ps-img-container {
      padding-top: 100%;
      position: relative;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal .ps-image img {
      position: absolute;
      left: 0;
      top: 0;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev,
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next {
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 100px !important;
      height: 100% !important;
      z-index: 2;
      padding: 20px;
      display: flex !important;
      cursor: pointer;
      transition: 0.5s;
      border-radius: 0;
      margin-top: 0;

      &:before {
        display: none !important;
      }
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span:after {
      content: '';
      display: block;
      position: absolute;
      width: 28px;
      height: 28px;
      background-size: cover;
      background-repeat: no-repeat;
      top: 50%;
      margin-top: -14px;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev {
      left: -50%;
      padding-left: 0;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(90deg, #fff 0, #fff 50%, hsla(0, 0%, 100%, 0)) !important;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNCw1LjZjLTAuMi0wLjItMC41LTAuMi0wLjcsMGwtNiw2Yy0wLjIsMC4yLTAuMiwwLjUsMCwwLjdsNiw2YzAuMiwwLjIsMC41LDAuMiwwLjcsMHMwLjItMC41LDAtMC43TDkuNywxMmw1LjYtNS42QzE1LjUsNi4yLDE1LjUsNS44LDE1LjQsNS42eiIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==);
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next {
      right: -50%;
      padding-right: 0;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(hsla(0, 0%, 100%, 0)),
        color-stop(50%, #fff),
        to(#fff)
      );
      background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff 50%, #fff) !important;
    }
    .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOS40LDUuNmMtMC4yLTAuMi0wLjUtMC4yLTAuNywwYy0wLjIsMC4yLTAuMiwwLjUsMCwwLjdsNS42LDUuNmwtNS42LDUuNmMtMC4yLDAuMi0wLjIsMC41LDAsMC43YzAuMiwwLjIsMC41LDAuMiwwLjcsMGw2LTZjMC4yLTAuMiwwLjItMC41LDAtMC43TDkuNCw1LjZ6IiBmaWxsPSIjMDAwIi8+PC9zdmc+);
      right: 0;
    }
    div[id^='photoslurpcontainer_'] > .block {
      display: none;
      height: 0;
    }
    body.catalog-product-view div[id^='photoslurpcontainer_'].ps-widget-ready > .block {
      display: inline;
    }

    @media (max-width: 992px) {
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next {
        right: 0;
        width: 50px;
      }
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal.ps-prev {
        left: 0;
        width: 50px;
      }
    }
    @media (max-width: 767px) {
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal .ps-img-container {
        height: 200px;
      }
      .ps-widget-ready
        .ps-carousel.ps-theme-modern
        .ps-carousel-slider.ps-slider-horizontal
        .ps-slider-container
        .ps-image {
        width: 40% !important;
      }
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next,
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev {
        background: transparent !important;
        height: 100%;
        padding: 0;
        width: 30px !important;
      }
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next:after,
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev:after {
        background-color: var(--white-color);
        background-position: 3px 5px;
        border-radius: 0;
        color: var(--black-color);
        height: 32px;
        width: 30px;
        margin-top: -16px;
      }
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-next:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOS40LDUuNmMtMC4yLTAuMi0wLjUtMC4yLTAuNywwYy0wLjIsMC4yLTAuMiwwLjUsMCwwLjdsNS42LDUuNmwtNS42LDUuNmMtMC4yLDAuMi0wLjIsMC41LDAsMC43YzAuMiwwLjIsMC41LDAuMiwwLjcsMGw2LTZjMC4yLTAuMiwwLjItMC41LDAtMC43TDkuNCw1LjZ6IiBmaWxsPSIjMDAwIi8+PC9zdmc+);
        background-size: 24px;
      }
      .ps-widget-ready .ps-carousel.ps-theme-modern .ps-carousel-slider.ps-slider-horizontal nav span.ps-prev:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNCw1LjZjLTAuMi0wLjItMC41LTAuMi0wLjcsMGwtNiw2Yy0wLjIsMC4yLTAuMiwwLjUsMCwwLjdsNiw2YzAuMiwwLjIsMC41LDAuMiwwLjcsMHMwLjItMC41LDAtMC43TDkuNywxMmw1LjYtNS42QzE1LjUsNi4yLDE1LjUsNS44LDE1LjQsNS42eiIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==);
        background-size: 24px;
      }
    }
  }

  .photoslurp_carousel .ps-widget-ready,
  div[id^='photoslurpcontainer_'] {
    clear: both;
  }
}
</style>

<template>
  <div class="zc-widget-config" :data-restaurant="zenchefId"></div>
</template>

<script lang="ts" setup>
import type { Component, Document, Page } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  document?: Document
  page: Page
}

const props = defineProps<Props>()

const componentProperties = props.component?.getProperties()
const zenchefId = componentProperties?.zenchefId

onMounted(() => {
  const el = document.getElementsByTagName('script')[0]
  if (document.getElementById('zenchef-sdk') || el.parentNode == null) return
  const js = document.createElement('script')
  js.id = 'zenchef-sdk'
  js.src = 'https://sdk.zenchef.com/v1/sdk.min.js'
  el.parentNode.insertBefore(js, el)
})
</script>
